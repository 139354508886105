import React, { useState } from "react";
import "./Register.css";

import logo from "../../assets/images/aipo-logo-white.png";
import line from "../../assets/images/register/line.png";
import bgImg from "../../assets/images/register/bg.png";
import rightBgImg from "../../assets/images/register/right-bg.png";
import rightMobileBgImg from "../../assets/images/register/right-mobile-bg.png";
import registerBtnImg from "../../assets/images/register/register-btn.png";
import trueV from "../../assets/images/register/v.png";
import falseX from "../../assets/images/register/x.png";
import ShowHidePassword from "../../components/common/Login-Register/ShowHidePassword.jsx";

const Register = () => {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const handleRegister = (e) => {
    console.log("e", e);
    console.log(password1);
    console.log(password2);
  };
  return (
    <>
      <div className="col-12 position-relative">
        <img
          className="w-100 cover h-100vh position-absolute top-0 start-0 z-n1"
          src={bgImg}
          alt=""
        />
        <div className="col-12 h-100vh aipo-mobile-h100vh d-flex flex-wrap">
          <div className="col-md-7 col-12">
            <div className="col-md-3 col-12">
              <img className="w-100 padding-10" src={logo} alt="" />
            </div>
          </div>
          <div className="col-md-5 col-12 position-relative">
            <img
              className="w-100 cover h-100vh d-none d-md-block position-absolute top-0 start-0 z-n1"
              src={rightBgImg}
              alt=""
            />
            <img
              className="w-100 cover h-100vh d-block d-md-none position-absolute top-0 start-0 z-n1"
              src={rightMobileBgImg}
              alt=""
            />
            <div className="col-12">
              <div className="padding-5-15 d-flex flex-wrap justify-content-center align-items-center">
                <h2 className="fz-3cqw fw-bold margin-bottom-5">
                  Create Your Account
                </h2>
                <img
                  className="w-100 margin-bottom-5 padding-x-3"
                  src={line}
                  alt=""
                />
                <div className="col-12 margin-bottom-5">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="text-nowrap m-0 fz-1-25cqw">Enter name</h6>
                    <div className="d-flex w-100 justify-content-end align-items-center padding-left-10">
                      <img className="w-auto h-100" src={trueV} alt="" />
                      <div className="padding-left-1 fz-1cqw">
                        Name looks great
                      </div>
                    </div>
                  </div>
                  <input
                    className="aipo-input"
                    type="text"
                    name="name"
                    placeholder="Name ..."
                  />
                </div>
                <div className="col-12 margin-bottom-5">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="text-nowrap m-0 fz-1-25cqw">
                      Enter email address
                    </h6>
                    <div className="d-flex w-100 justify-content-end align-items-center padding-left-10">
                      <img className="w-auto h-100" src={trueV} alt="" />
                      <div className="padding-left-1 fz-1cqw">
                        We will email you a confirmation
                      </div>
                    </div>
                  </div>
                  <input
                    className="aipo-input"
                    type="email"
                    name="email"
                    placeholder="Email ..."
                  />
                </div>
                <div className="col-12 margin-bottom-5">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="text-nowrap m-0 fz-1-25cqw">
                      Enter password
                    </h6>
                    <div className="d-flex w-100 justify-content-end align-items-center padding-left-10">
                      <img className="w-auto h-100" src={trueV} alt="" />
                      <div className="padding-left-1 fz-1cqw">
                        Password must have at least 8 characters
                      </div>
                    </div>
                  </div>
                  <ShowHidePassword
                    placeholder="Password ..."
                    setPassword={setPassword1}
                  />
                </div>
                <div className="col-12 margin-bottom-5">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="text-nowrap m-0 fz-1-25cqw">
                      Enter password
                    </h6>
                    <div className="d-flex w-100 justify-content-end align-items-center padding-left-10">
                      <img className="w-auto h-100" src={trueV} alt="" />
                      <div className="padding-left-1 fz-1cqw">
                        Password must match
                      </div>
                    </div>
                  </div>
                  <ShowHidePassword
                    placeholder="Password again ..."
                    setPassword={setPassword2}
                  />
                </div>
                <img
                  className="w-100 margin-bottom-5 padding-x-3"
                  src={line}
                  alt=""
                />
                <div
                  className="position-relative col-12 margin-bottom-5 pointer-cursor"
                  onClick={handleRegister}
                >
                  <img className="w-100" src={registerBtnImg} alt="" />
                  <div className="position-absolute top-50 start-50 translate-middle fz-2-5cqw">
                    Register
                  </div>
                </div>
                <div className="col-12 fz-1cqw">error messages</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
