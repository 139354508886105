import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Register from "./pages/Register/Register";
const App = () => {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/" element={<Register />} /> {/* main page */}
        <Route exact path="/register" element={<Register />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default App;
