import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
const ShowHidePassword = ({ placeholder = "Type here...", setPassword }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className="position-relative">
        <input
          className="aipo-input"
          type={showPassword ? "text" : "password"}
          name="password"
          placeholder={placeholder}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="aipo-hide-icon" onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </div>
      </div>
    </>
  );
};

export default ShowHidePassword;
